import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/useremployees', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployees/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployees/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/useremployees', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/useremployees', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/useremployees/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPositionList(ctx, {employeeId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeepositions/${employeeId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewPosition(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeepositions/${employeeId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPosition(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeepositions/${employeeId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addPosition(ctx, {employeeId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/useremployeepositions/${employeeId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editPosition(ctx, {employeeId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/useremployeepositions/${employeeId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removePosition(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/useremployeepositions/${employeeId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchLeaveInfoList(ctx, {userId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/userleaveentitlements/${userId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewLeaveInfo(ctx, {userId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/userleaveentitlements/${userId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchLeaveInfo(ctx, {userId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/userleaveentitlements/${userId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addLeaveInfo(ctx, {userId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/userleaveentitlements/${userId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editLeaveInfo(ctx, {userId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/userleaveentitlements/${userId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeLeaveInfo(ctx, {userId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/userleaveentitlements/${userId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        current(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployees/current`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        count(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployees/count`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDocumentList(ctx, {employeeId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeedocuments/${employeeId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewDocument(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeedocuments/${employeeId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDocument(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeedocuments/${employeeId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDocument(ctx, {employeeId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/useremployeedocuments/${employeeId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editDocument(ctx, {employeeId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/useremployeedocuments/${employeeId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeDocument(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/useremployeedocuments/${employeeId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPayrollList(ctx, {employeeId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeepayrolls/${employeeId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewPayroll(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeepayrolls/${employeeId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPayroll(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/useremployeepayrolls/${employeeId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addPayroll(ctx, {employeeId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/useremployeepayrolls/${employeeId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editPayroll(ctx, {employeeId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/useremployeepayrolls/${employeeId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removePayroll(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/useremployeepayrolls/${employeeId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        export(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/useremployees/export', {})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportLeave(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/userleaveentitlements/export', {})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetList(ctx, {employeeId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assets/by-user/${employeeId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewAsset(ctx, {employeeId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assets/by-user/${employeeId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
